var ability_modifiers = [
    { stat: 3, mod: -3, modtxt: '(-3)' },
    { stat: 4, mod: -2, modtxt: '(-2)' },
    { stat: 5, mod: -2, modtxt: '(-2)' },
    { stat: 6, mod: -1, modtxt: '(-1)' },
    { stat: 7, mod: -1, modtxt: '(-1)' },
    { stat: 8, mod: -1, modtxt: '(-1)' },
    { stat: 9, mod: 0, modtxt: '' },
    { stat: 10, mod: 0, modtxt: '' },
    { stat: 11, mod: 0, modtxt: '' },
    { stat: 12, mod: 0, modtxt: '' },
    { stat: 13, mod: 1, modtxt: '(+1)' },
    { stat: 14, mod: 1, modtxt: '(+1)' },
    { stat: 15, mod: 1, modtxt: '(+1)' },
    { stat: 16, mod: 2, modtxt: '(+2)' },
    { stat: 17, mod: 2, modtxt: '(+2)' },
    { stat: 18, mod: 3, modtxt: '(+3)' },
]

var lucky_signs = [
    { roll: 0, name: 'Bad luck', desc: 'Not enough luck for the stars to care.' },
    { roll: 1, name: 'Bad moon baby', desc: 'Use modifier with all attack rolls.' },
    { roll: 2, name: 'Hummingbird waltz', desc: 'Use modifier with all initiative rolls.' },
    { roll: 3, name: 'Magpie’s whisper`', desc: `Apply the modifier in any attempt to mimic sounds and voices. Make an Intelligence check vs. an average 
    DC of 8 further modified by the judge if needed.`},
    { roll: 4, name: 'Armadillo hide', desc: `Apply the modifier to AC` },
    { roll: 5, name: 'Fox’s guile', desc: `Apply the modifier to any rolls related to detecting or disarming traps.` },
    { roll: 6, name: 'Luck o’ the hare', desc: `Use the modifier to adjust critical hit and fumble chart rolls. This modifier stacks on top of any existing 
    modifiers.`},
    { roll: 7, name: 'Bountiful harvest', desc: `Apply the modifier to any newly acquired hit point rolls. In the case of a negative modifier, remember 
    characters will always receive at least 1 hp.`},
    { roll: 8, name: 'Whitetail grace', desc: `Use modifier with all Reflex saving throws.` },
    { roll: 9, name: 'Widow’s kiss', desc: `Apply the modifier to any poison-related saving throws.` },
    { roll: 10, name: 'Badger cunnin’', desc: `Apply modifier to all melee attack rolls.` },
    { roll: 11, name: 'Crow’s tongue', desc: `Use the modifier to determine additional languages. In the case of a negative modifier, deduct a language 
    (possibly given from a high Intelligence bonus) per point of negative modifier.` },
    { roll: 12, name: 'Shadow-kin', desc: `Apply modifier to all corruption rolls.` },
    { roll: 13, name: 'Desert rat', desc: `Use modifier with all Fortitude saving throws.` },
    { roll: 14, name: 'Guardian angel/meddling devil', desc: `One saving throw may be rerolled per day for each point of positive modifier. In the case of 
    a negative modifier, the character must reroll a saving throw where the unmodified die result is an odd number. The second 
    roll stands regardless of the total. One reroll must be made for each point of negative modifier.` },
    { roll: 15, name: 'Ley-line tuning fork', desc: `Apply the modifier to any rolls related to casting spells or using magic items where a roll is called for.` },
    { roll: 16, name: 'Unholy allergy', desc: `Use modifier to all turn back the night rolls (see revelator class).` },
    { roll: 17, name: `Lucky rabbit's foot`, desc: `Apply the modifier to all saving throws.` },
    { roll: 18, name: `Flying rat’s radar`, desc: `Apply the modifier to any checks to avoid surprise.` },
    { roll: 19, name: 'Grizzly swipe', desc: `Use the modifier with all melee damage rolls.` },
    { roll: 20, name: 'Hawkeye', desc: `Apply the modifier to any ranged attack roll.` },
    { roll: 21, name: 'Equine charmer', desc: `Use modifier on all horsemanship rolls.` },
    { roll: 22, name: 'Jackalope mojo', desc: `Character selects one type of saving throw to be affected by the modifier at the start of each new day.` },
    { roll: 23, name: 'Lightnin’ rod', desc: `Use modifier on all magical damage rolls.` },
    { roll: 24, name: 'Sidewinder strike', desc: `Apply the modifier to all ranged damage rolls.` },
    { roll: 25, name: 'Roadrunner’s pace', desc: `+/- 5’ to movement rate for each point of modifier.` },
    { roll: 26, name: 'Liver of iron', desc: `Apply the modifier to all saving throws related to alcohol.` },
    { roll: 27, name: '6th sense', desc: `Apply the modifier to all attempts at discovering hidden things.` },
    { roll: 28, name: 'Mountain tuff’', desc: `Apply the modifier to any healing rolls where the character is the recipient. In the case of a negative modifier 
    remember at least one hit point will be gained.` },
    { roll: 29, name: 'Holy roller', desc: `Apply the modifier to any attempts at practical or magical healing.` },
    { roll: 30, name: 'Stray cat’s strut', desc: `Use modifier on all stealth/hide related rolls.` },
]

var occupations = [
    { roll: 1, occupation: 'Actor', weapon: 'Knife (d4)', goods: 'Theatrical costumes ($10)'},
    { roll: 2, occupation: 'Pinkerton agent', weapon: 'Pistol*', goods: 'Detective badge and manacles ($8)'},
    { roll: 3, occupation: 'Author', weapon: 'Quill pen (d2)', goods: 'Jar of ink ($.50)'},
    { roll: 4, occupation: 'Photographer', weapon: 'Tripod (d4)', goods: 'Camera w/6 plates ($25)'},
    { roll: 5, occupation: 'Hyptotist', weapon: 'Derringer*', goods: 'Gold pocket watch ($8)'},
    { roll: 6, occupation: 'Juggler', weapon: 'Heavy bowling pin (d4) x 4', goods: 'Leather balls ($2)'},
    { roll: 7, occupation: 'Librarian', weapon: 'Heavy book (d3)', goods: 'Pet rabbit ($1)'},
    { roll: 8, occupation: 'Magician', weapon: 'Want that turns into a knife (d3)', goods: 'Pet rabbit ($1)'},
    { roll: 9, occupation: 'Musician', weapon: 'Banjo (d4)', goods: 'Book of sheet music ($2)'},
    { roll: 10, occupation: 'Painter', weapon: 'Large paintbrush (d2)', goods: 'Rare pigments ($10)'},
    { roll: 11, occupation: 'Salesman', weapon: 'Derringer*', goods: 'Trunk with random baubles ($15)'},
    { roll: 12, occupation: 'Puppeteer', weapon: 'Wooden puppet (d2)', goods: 'Travel case ($5)'},
    { roll: 13, occupation: 'Sculptor', weapon: 'Trowel (d3)', goods: 'Bucket of clay ($2)'},
    { roll: 14, occupation: 'Showman', weapon: 'Throwing knives (d3) x 6', goods: 'Stage clothes ($5)'},
    { roll: 15, occupation: 'Sideshow freak', weapon: 'Barbell (d6)', goods: 'Muscle oil ($1)'},
    { roll: 16, occupation: 'Singer', weapon: 'Pocket knife (d3)', goods: 'Throat lozenges in tin ($1)'},
    { roll: 17, occupation: 'Sword swallower', weapon: 'Sword (d8)', goods: 'Stage clothes ($5)'},
    { roll: 18, occupation: 'Thespian', weapon: 'Sword cane (d6)', goods: 'Book of monologues ($2)'},
    { roll: 19, occupation: 'Orphan', weapon: 'Pocket Knife (d3)', goods: 'Stolen jar of candy ($2)'},
    { roll: 20, occupation: 'Camp cook', weapon: 'Meat cleaver (d6)', goods: 'Mess kit ($10)'},
    { roll: 21, occupation: 'Cattle drover', weapon: 'Pistol*', goods: 'Saddle rig ($20)'},
    { roll: 22, occupation: 'Stagecoach driver', weapon: 'Bullwhip (d3)', goods: 'Leather gloves ($3)'},
    { roll: 23, occupation: 'Ex-servant', weapon: 'Knife (d4)', goods: 'Average suit ($5)'},
    { roll: 24, occupation: 'Farmer', weapon: 'Scythe (d6)', goods: 'Sack of vegetables ($2)'},
    { roll: 25, occupation: 'Farrier', weapon: 'Small hammer (d4)', goods: 'Bag of horseshoes ($3)'},
    { roll: 26, occupation: 'Fisherman', weapon: 'Knife (d4)', goods: 'd10 Largemouth bass ($.25 each)'},
    { roll: 27, occupation: 'Herdsman', weapon: 'Bowie knife (d6)', goods: 'Loyal cattle dog (priceless)'},
    { roll: 28, occupation: 'Homesteader', weapon: 'Rifle*', goods: 'Carpentry tools ($5)'},
    { roll: 29, occupation: 'Housekeeper', weapon: 'Brook (d2)', goods: 'Ornate vase ($5)'},
    { roll: 30, occupation: 'Hunter', weapon: 'Rifle*', goods: 'Bear trap ($2)'},
    { roll: 31, occupation: 'Lumberjack', weapon: 'Axe (d6)', goods: 'Wool coat ($5)'},
    { roll: 32, occupation: 'School teacher', weapon: 'Heavy book (d3)', goods: 'Stack of school books ($3)'},
    { roll: 33, occupation: 'Miner', weapon: 'Pick (d5)', goods: 'Singing canary w/ small cage ($5)'},
    { roll: 34, occupation: 'Rancher', weapon: 'Pistol*', goods: `50' Spool of barbed wire ($2)`},
    { roll: 35, occupation: 'Prospector', weapon: 'Bowie knife (d6)', goods: 'Sifting pan and gold nugget ($10)'},
    { roll: 36, occupation: 'Butler', weapon: 'Brass candlestick (d3)', goods: `Butler's suit ($10)`},
    { roll: 37, occupation: 'Teamster', weapon: 'Shotgun*', goods: `Saddlebags ($3)`},
    { roll: 38, occupation: 'Twister (horse breaker)', weapon: 'Bullwhip (d3)', goods: `Bridle and bit ($3)`},
    { roll: 39, occupation: 'Bartender', weapon: 'Pistol*', goods: `Small cask of whiskey ($10)`},
    { roll: 40, occupation: 'Bouncer', weapon: 'Billy club (d4)', goods: `Bowler hat ($3)`},
    { roll: 41, occupation: 'Bounty hunter', weapon: 'Rifle*', goods: `Shackles ($3)`},
    { roll: 42, occupation: 'Burglar', weapon: 'Knife (d4)', goods: `Dark clothes ($5)`},
    { roll: 43, occupation: 'Carpetbagger', weapon: 'Sword cane (d6)', goods: `Carpetbag ($2)`},
    { roll: 44, occupation: 'Dancehall girl', weapon: 'Straight razor (d3)', goods: `Fine corset ($10)`},
    { roll: 45, occupation: 'Deserter', weapon: 'Pistol*', goods: `Worn uniform ($2)`},
    { roll: 46, occupation: 'Gambler', weapon: 'Derringer*', goods: `Nice suite & derby hat ($10)`},
    { roll: 47, occupation: 'Fortune teller', weapon: 'Knife (d4)', goods: `Crystal ball ($4)`},
    { roll: 48, occupation: 'Horse thief', weapon: 'Pistol*', goods: `Stolen branded horse ($50)`},
    { roll: 49, occupation: 'Outlaw', weapon: 'Pistol*', goods: `Wanted poster (self) & 10 silver dollars`},
    { roll: 50, occupation: 'Pickpocket', weapon: 'Pocket knife (d3)', goods: `Wallet w/ ($d5)`},
    { roll: 51, occupation: 'Peddler', weapon: 'Knife (d4)', goods: `Trinkets worth ($d12)`},
    { roll: 52, occupation: 'Politician', weapon: 'Heavy cane (d4)', goods: `$2d12 in fine leather wallet`},
    { roll: 53, occupation: 'Snake oil salesman', weapon: 'Bowie knife (d6)', goods: `Bottle of Dr.Hobb's cure-all tonic ($1)`},
    { roll: 54, occupation: 'Auctioneer', weapon: 'Gavel (d2)', goods: `Unclaimed trinkets ($6)`},
    { roll: 55, occupation: 'Banker', weapon: 'Quill pen (d2)', goods: `Bag of 25 silver dollars`},
    { roll: 56, occupation: 'Boatman', weapon: 'Gaff hook (d5)', goods: `Large net ($1)`},
    { roll: 57, occupation: 'Bookkeeper', weapon: 'Letter opener (d2)', goods: `Ledger books ($2)`},
    { roll: 58, occupation: 'Coachman', weapon: 'Shotgun*', goods: `Heavy duster ($5)`},
    { roll: 59, occupation: 'Dentist', weapon: 'Small hand drill (d3)', goods: `Bottle of laudanum/ d4 doses ($4)`},
    { roll: 60, occupation: 'Doctor', weapon: 'Surgical knife (d3)', goods: `Medical kit ($15)`},
    { roll: 61, occupation: 'Grocer', weapon: 'Club (d4)', goods: `Jar of pickled herring (red) ($6)`},
    { roll: 62, occupation: 'Innkeeper', weapon: 'Derringer*', goods: `Incriminating ledger ($?)`},
    { roll: 63, occupation: 'Laborer', weapon: 'Shovel (d5)', goods: `50' Rope ($0.50)`},
    { roll: 64, occupation: 'Launderer', weapon: 'Straight razor (d3)', goods: `Clean parcel of clothes ($5)`},
    { roll: 65, occupation: 'Medical assistant', weapon: 'Surgical knife (d3)', goods: `D3 poultices (each heals d2 hit points $.50 each)`},
    { roll: 66, occupation: 'Plumber', weapon: 'Pipe wrench (d5)', goods: `Brass fittings ($2)`},
    { roll: 67, occupation: 'Railroad man', weapon: 'Heavy hammer (d5)', goods: `d10 iron spikes ($2)`},
    { roll: 68, occupation: 'Seamstress', weapon: 'Shears (d3)', goods: `Bolt of fabric ($d10)`},
    { roll: 69, occupation: 'Veterinarian', weapon: 'Surgical knife (d3)', goods: `Medical kit ($15)`},
    { roll: 70, occupation: 'Calvaryman', weapon: 'Pistol*', goods: `Worn saddlebags ($3)`},
    { roll: 71, occupation: 'Civil engineer', weapon: 'Tripod (d4)', goods: `Brass leveling instrument ($30)`},
    { roll: 72, occupation: 'Clergyman', weapon: 'Extra heavy Bible (d3)', goods: `Bottle of red wine ($2)`},
    { roll: 73, occupation: 'Infantryman', weapon: 'Rifle*', goods: `Mess kit ($10)`},
    { roll: 74, occupation: 'Interpreter', weapon: 'Quill pen (d2)', goods: `Translation guide ($2)`},
    { roll: 75, occupation: 'Journalist', weapon: '1d6 Sharp pencils (d2)', goods: `Leather journal ($1)`},
    { roll: 76, occupation: 'Judge', weapon: 'Gavel (d2)', goods: `Book of law ($4)`},
    { roll: 77, occupation: 'Nun', weapon: 'Extra heavy Bible (d3)', goods: `Black habit & nice Bible ($2)`},
    { roll: 78, occupation: 'Preacher', weapon: 'Canclestick (d3)', goods: `Nice suite & Bible ($2)`},
    { roll: 79, occupation: 'Professor', weapon: 'Thick cane (d3)', goods: `Stack of books ($5)`},
    { roll: 80, occupation: 'Riverboat pilot', weapon: 'Staff (d4)', goods: `Lantern ($2)`},
    { roll: 81, occupation: 'Scout', weapon: 'Rifle*', goods: `Bearskin coat ($10)`},
    { roll: 82, occupation: 'Soldier', weapon: 'Pistol*', goods: `Uniform & mess kit ($5)`},
    { roll: 83, occupation: 'Steward', weapon: 'Billy club (d4)', goods: `Padlock & key ($2)`},
    { roll: 84, occupation: 'Telegraph operator', weapon: 'Wire snips (d3)', goods: `Important message ($?)`},
    { roll: 85, occupation: 'Undertaker', weapon: 'Derringer*', goods: `Measuring tape and box of nails ($.50)`},
    { roll: 86, occupation: 'Baker', weapon: 'Rolling pin (d3)', goods: `Long lasting fruit cake ($.50)`},
    { roll: 87, occupation: 'Barber', weapon: 'Straigt razor (d3)*', goods: `Shaving soap ($.50)`},
    { roll: 88, occupation: 'Blacksmith', weapon: 'Heavy hammer (d5)', goods: `5ibs. Pig iron ($4)`},
    { roll: 89, occupation: 'Brewer', weapon: 'Keg staff (d4)', goods: `Keg of ale ($10)`},
    { roll: 90, occupation: 'Butcher', weapon: 'Cleaver (d6)', goods: `Side of beef ($2)`},
    { roll: 91, occupation: 'Carpenter', weapon: 'Hammer (d4)', goods: `Tool bag ($5)`},
    { roll: 92, occupation: 'Cigar maker', weapon: 'Knife (d4)', goods: `Box of fine cigars ($8)`},
    { roll: 93, occupation: 'Clothier', weapon: 'Shears (d3)', goods: `Set of fine clothes ($12)`},
    { roll: 94, occupation: 'Distiller', weapon: 'Bowie knife (d6)', goods: `1d4 jars of shine ($1 each)`},
    { roll: 95, occupation: 'Gunsmith', weapon: 'Pistol*', goods: `Gun cleaning kit ($3)`},
    { roll: 96, occupation: 'Hatter', weapon: 'Shears (d3)', goods: `Very fine beaver hat ($10)`},
    { roll: 97, occupation: 'Jeweler', weapon: 'Derringer*', goods: `Jewelers tools ($8)`},
    { roll: 98, occupation: 'Locksmith', weapon: 'Chain and lock (d4)', goods: `Lock pick set ($8)`},
    { roll: 99, occupation: 'Potter', weapon: 'Chisel (d2)', goods: `Lump of clay ($.50)`},
    { roll: 100, occupation: 'Bum', weapon: 'Knife (d4)', goods: `Jug of white lightning ($1)`},
]

export { ability_modifiers, lucky_signs, occupations }